import React, { Component } from 'react';
import { INPUT_TYPES, FIELD_WIDTHS } from '../../stateless_components/inputs/elements/FieldWrapper';
import { getFormikErrors } from '../../services/formHelper';
import { FormSection } from '../../stateless_components/form_wrappers/FormSection';
import { SaluteInputField } from '../../stateless_components/inputs/fields/SaluteInputField';
import ObjectSelector from './ObjectSelector';
import Comments from '../Commets';
import { FindingFooter } from './Footer';
import {
  newAlsoNotifiedPeopleAttributes,
  alsoNotifiedPeople,
} from '../../services/alsoNotifiedPeopleHelper';
import { momentFormatter, newMoment } from '../../services/dateFormatUtils';

const I18nPath = 'activerecord.attributes.inspection_finding';

class Form extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { isSubmitting, errors, onValidationError } = this.props;
    if (Object.keys(errors).length > 0 && isSubmitting) {
      onValidationError();
    }
  }

  finalized = () => {
    return this.props.values.status === 'finalized';
  };

  updateInspectionFindingsAlsoNotifiedPeopleAttributes = newPeople => {
    const { values, setFieldValue } = this.props;
    const newAttributes = newAlsoNotifiedPeopleAttributes(
      values.inspection_findings_also_notified_people_attributes,
      newPeople,
    );
    setFieldValue('inspection_findings_also_notified_people_attributes', newAttributes);
  };

  populateFieldsBasedOnFindingDefinition = (value, triggerredOnInit, setFieldValue, values) => {
    if (!triggerredOnInit || !values.finding) {
      value.finding_full && setFieldValue('finding', value.finding_full);
    }
    if (!triggerredOnInit || !values.corrective_action) {
      value.corrective_action && setFieldValue('corrective_action', value.corrective_action);
    }
    if (!triggerredOnInit || !values.due_date) {
      value.days_to_correct &&
        setFieldValue(
          'due_date',
          momentFormatter.serverDate(newMoment().add(value.days_to_correct, 'days')),
        );
    }
    if (!triggerredOnInit || !values.website_info) {
      value.website_info && setFieldValue('website_info', value.website_info);
    }
    if (!triggerredOnInit || !values.label_ids) {
      value.label_ids && setFieldValue('label_ids', value.label_ids);
    }
  };

  isPeopleGroupSelectorDisabled = values =>
    this.finalized() || values.inspection_checklist_question_id || values.id;

  prepareStatusesForFinding = (status, enums) => {
    const openedStatuses = ['open', 'completed'];
    if (openedStatuses.includes(status)) {
      return enums.filter(hash => hash.value !== 'finalized');
    }

    return enums;
  };

  sendEmailNotificationsCheckbox = () => {
    const { handleBlur, handleChange, values, touched, errors } = this.props;
    return (
      <>
        <div className="d-flex row mt-3">
          <div className="col-lg-3 col-sm-3 col-md-4 col-xs-12" />
          <div
            className="col normal-text"
            dangerouslySetInnerHTML={{ __html: I18n.t('inspection_findings.hints.saving_modal') }}
          />
        </div>
        <SaluteInputField
          labelProps={{ label: I18n.t(`${I18nPath}.send_email_notifications`) }}
          inputProps={{
            handleBlur,
            value: values.send_email_notifications,
            name: 'send_email_notifications',
            onChange: handleChange,
          }}
          fieldWidth={FIELD_WIDTHS.WIDE}
          error={getFormikErrors('send_email_notifications', touched, errors)}
          type={INPUT_TYPES.CHECKBOX}
        />
      </>
    );
  };

  render = () => {
    const {
      handleChange,
      values,
      errors,
      setFieldValue,
      handleBlur,
      touched,
      setFieldTouched,
      findingProps: {
        relatedEntityName,
        peopleGroupsCount,
        findingStatuses,
        customInvestigableTypesNames,
      },
    } = this.props;

    return (
      <>
        <FormSection title={I18n.t(`inspection_findings.form.overview`)}>
          {relatedEntityName === 'Violation' && (
            <SaluteInputField
              labelProps={{
                label: I18n.t(`${I18nPath}.regulatory_description`),
                hint:
                  'Optional formal description.<br>Usually a verbatim copy ' +
                  'of deficiency description from a document provided by an external agency.',
              }}
              inputProps={{
                disabled: this.finalized(),
                handleBlur,
                value: values.regulatory_description,
                name: 'regulatory_description',
                onChange: handleChange,
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('regulatory_description', touched, errors)}
              type={INPUT_TYPES.TEXT_AREA}
            />
          )}
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.identifier`) }}
            inputProps={{
              value: values.identifier,
              name: 'identifier',
              onChange: handleChange,
              disabled: true,
              placeholder: '##############',
              handleBlur,
            }}
            error={getFormikErrors('identifier', touched, errors)}
            type={INPUT_TYPES.TEXT_FIELD}
          />
          {values.internal && peopleGroupsCount > 1 && (
            <SaluteInputField
              labelProps={{
                label: I18n.t(`${I18nPath}.people_group_id`),
                required: true,
              }}
              inputProps={{
                disabled: this.isPeopleGroupSelectorDisabled(values),
                setFieldTouched,
                value: values.people_group_id,
                name: 'people_group_id',
                initialSelection: values.people_group_id,
                apiPath: 'peopleGroups.index',
                intitialSelectionApiPath: 'peopleGroups.show',
                handleChange: value => setFieldValue('people_group_id', value),
              }}
              fieldWidth={FIELD_WIDTHS.WIDE}
              error={getFormikErrors('people_group_id', touched, errors)}
              type={INPUT_TYPES.ASYNC_SELECT}
            />
          )}
          <SaluteInputField
            labelProps={{
              label: I18n.t(`${I18nPath}.initiated_at`),
              required: true,
            }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              date: values.initiated_at,
              name: 'initiated_at',
              setFieldValue,
            }}
            error={getFormikErrors('initiated_at', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.DATETIME_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.inspection_finding_definition_id`) }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.inspection_finding_definition_id,
              name: 'inspection_finding_definition_id',
              initialSelection: values.inspection_finding_definition_id,
              apiPath: 'inspectionFindingDefinition.index',
              intitialSelectionApiPath: 'inspectionFindingDefinition.show',
              shouldTriggerSetSelectedRecordOnInit: !values.id,
              handleChange: value => setFieldValue('inspection_finding_definition_id', value),
              requestParams: { with_allow_findings: true },
              mapResult: item => ({
                value: item.id,
                id: item.id,
                label: item.text || item.header,
                header: item.header,
                details: item.details,
                finding_full: item.finding_full,
                corrective_action: item.corrective_action,
                days_to_correct: item.days_to_correct,
                website_info: item.website_info,
                label_ids: item.label_ids,
              }),
              setSelectedRecord: (value, triggerredOnInit) =>
                this.populateFieldsBasedOnFindingDefinition(
                  value,
                  triggerredOnInit,
                  setFieldValue,
                  values,
                ),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('inspection_finding_definition_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.finding`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.finding,
              name: 'finding',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('finding', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.corrective_action`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.corrective_action,
              name: 'corrective_action',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('corrective_action', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.website_info`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.website_info,
              name: 'website_info',
              onChange: handleChange,
            }}
            error={getFormikErrors('website_info', touched, errors)}
            fieldWidth={FIELD_WIDTHS.WIDE}
            type={INPUT_TYPES.TEXT_FIELD}
          />
          <ObjectSelector
            customInvestigableTypesNames={customInvestigableTypesNames}
            {...this.props}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.label_ids`) }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.label_ids,
              name: 'label_ids',
              initialSelection: values.label_ids,
              apiPath: 'labels.index',
              intitialSelectionApiPath: 'labels.show',
              handleChange: value => {
                setFieldValue('label_ids', value)
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('label_ids', touched, errors)}
            type={INPUT_TYPES.MULTIPLE_ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.attachments`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.general_attachments,
              name: 'general_attachments',
              onChange: handleChange,
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.FULL}
            error={getFormikErrors('general_attachments', touched, errors)}
            type={INPUT_TYPES.FILES_UPLOAD}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.general_links`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.general_links,
              name: 'general_links',
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.FULL}
            error={getFormikErrors('general_links', touched, errors)}
            type={INPUT_TYPES.LINKS}
          />
        </FormSection>
        <hr />
        <FormSection title={I18n.t(`inspection_findings.form.corrective_actions_completed`)}>
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.follow_up_required`), required: true }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.follow_up_required,
              name: 'follow_up_required',
              initialSelection: values.follow_up_required,
              options: () => {
                return {
                  options: [
                    {
                      id: 0,
                      value: false,
                      header: I18n.t('inspection_findings.follow_up_required_labels.no'),
                    },
                    {
                      id: 1,
                      value: true,
                      header: I18n.t('inspection_findings.follow_up_required_labels.yes'),
                    },
                  ],
                  hasMore: false,
                };
              },
              handleChange: value => setFieldValue('follow_up_required', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('follow_up_required', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{
              label: I18n.t(`${I18nPath}.due_date`),
            }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              date: values.due_date,
              name: 'due_date',
              setFieldValue,
            }}
            error={getFormikErrors('due_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.ehs_inspector_id`) }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.ehs_inspector_id,
              name: 'ehs_inspector_id',
              initialSelection: values.ehs_inspector_id,
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => {
                setFieldValue('ehs_inspector_id', value);
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('ehs_inspector_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />

          <SaluteInputField
            labelProps={{
              label: I18n.t(`${I18nPath}.corrected_date`),
            }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              date: values.corrected_date,
              name: 'corrected_date',
              setFieldValue,
            }}
            error={getFormikErrors('corrected_date', touched, errors)}
            type={INPUT_TYPES.DATE_PICKER}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.corrected_comments`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.corrected_comments,
              name: 'corrected_comments',
              onChange: handleChange,
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('corrected_comments', touched, errors)}
            type={INPUT_TYPES.TEXT_AREA}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.status`), required: true }}
            inputProps={{
              disabled: this.finalized(),
              value: values.status,
              setFieldTouched,
              initialSelection: values.status,
              name: 'status',
              options: () => ({
                options: this.prepareStatusesForFinding(values.status, findingStatuses),
                has_more: false,
              }),
              handleChange: value => setFieldValue('status', value),
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('status', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.attachments`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.corrective_action_attachments,
              name: 'corrective_action_attachments',
              onChange: handleChange,
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.FULL}
            error={getFormikErrors('corrective_action_attachments', touched, errors)}
            type={INPUT_TYPES.FILES_UPLOAD}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.corrective_action_links`) }}
            inputProps={{
              disabled: this.finalized(),
              handleBlur,
              value: values.corrective_action_links,
              name: 'corrective_action_links',
              modalOnModal: true,
            }}
            fieldWidth={FIELD_WIDTHS.FULL}
            error={getFormikErrors('corrective_action_links', touched, errors)}
            type={INPUT_TYPES.LINKS}
          />
          {values.id && <Comments apiUrl={`/findings/${values.id}/comments`} role="internal" />}
        </FormSection>
        <hr />
        <FormSection
          title={I18n.t(`inspection_findings.form.assigned_to`)}
          hint={I18n.t('inspection_findings.hints.assigned')}
        >
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.creator_id`), required: true }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.creator_id,
              name: 'creator_id',
              initialSelection: values.creator_id,
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => {
                setFieldValue('creator_id', value);
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('creator_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{
              label: I18n.t(`${I18nPath}.responsible_person_id`),
              required: true,
              hint: I18n.t('inspections.inspections.form.modals.finding.hints.responsible_person'),
            }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: values.responsible_person_id,
              name: 'responsible_person_id',
              initialSelection: values.responsible_person_id,
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => {
                setFieldValue('responsible_person_id', value);
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('responsible_person_id', touched, errors)}
            type={INPUT_TYPES.ASYNC_SELECT}
          />
          <SaluteInputField
            labelProps={{ label: I18n.t(`${I18nPath}.also_notified_people_ids`) }}
            inputProps={{
              disabled: this.finalized(),
              setFieldTouched,
              value: alsoNotifiedPeople(values.inspection_findings_also_notified_people_attributes),
              name: 'also_notified_people_ids',
              initialSelection: alsoNotifiedPeople(
                values.inspection_findings_also_notified_people_attributes,
              ),
              apiPath: 'people.index',
              intitialSelectionApiPath: 'people.show',
              handleChange: value => {
                this.updateInspectionFindingsAlsoNotifiedPeopleAttributes(value);
              },
            }}
            fieldWidth={FIELD_WIDTHS.WIDE}
            error={getFormikErrors('also_notified_people_ids', touched, errors)}
            type={INPUT_TYPES.MULTIPLE_ASYNC_SELECT}
          />
          {!this.finalized() && this.sendEmailNotificationsCheckbox()}
        </FormSection>
        {this.finalized() && (
          <>
            <hr />
            <FormSection title="Would you like to reopen this Finding?">
              <SaluteInputField
                labelProps={{ label: I18n.t(`${I18nPath}.audit_comment`), required: true }}
                inputProps={{
                  handleBlur,
                  value: values.audit_comment,
                  name: 'audit_comment',
                  onChange: handleChange,
                }}
                fieldWidth={FIELD_WIDTHS.WIDE}
                error={getFormikErrors('audit_comment', touched, errors)}
                type={INPUT_TYPES.TEXT_AREA}
              />
              {this.sendEmailNotificationsCheckbox()}
            </FormSection>
          </>
        )}
        <FindingFooter {...this.props} />
      </>
    );
  };
}

export default Form;
