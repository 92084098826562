/** Lib */
import { create } from 'apisauce';
import queryStringParser from 'qs';

const SESSION_EXPIRED_ERR_MSG = 'Your session expired. Please sign in again to continue.';
const api = create({
  baseURL: '/',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

api.addRequestTransform(request => {
  request.headers['X-CSRF-Token'] = document.head.querySelector('[name=csrf-token]').content;
});

api.addMonitor(response => {
  if (response.status === 401 && response.data.error === SESSION_EXPIRED_ERR_MSG) {
    window.location.reload();
  }
});

const serializeParams = (params = {}) =>
  queryStringParser.stringify(params, { arrayFormat: 'brackets' });

export const API = {
  peopleGroups: {
    index: params =>
      api.get(`/api/people_groups.json?available_people_groups=true&${serializeParams(params)}`),
    show: id => api.get(`/api/people_groups/${id}.json`),
  },
  people: {
    index: params => api.get(`/api/people.json?${serializeParams(params)}`),
    show: id => api.get(`/api/people/${id}.json`),
  },
  agencies: {
    index: params => api.get(`/api/agencies.json?${serializeParams(params)}`),
    show: id => api.get(`/api/agencies/${id}.json`),
  },
  campuses: {
    index: params => api.get(`/api/campuses.json?${serializeParams(params)}`),
    show: id => api.get(`/api/campuses/${id}.json`),
  },
  buildings: {
    index: params => api.get(`/api/buildings.json?${serializeParams(params)}`),
    show: id => api.get(`/api/buildings/${id}.json`),
  },
  buildings_floors: {
    index: params => api.get(`/api/buildings_floors.json?${serializeParams(params)}`),
    show: id => api.get(`/api/buildings_floors/${id}.json`),
  },
  labs: {
    index: params => api.get(`/api/labs.json?${serializeParams(params)}`),
    show: id => api.get(`/api/labs/${id}.json`),
  },
  departments: {
    index: params => api.get(`/api/departments.json?${serializeParams(params)}`),
    show: id => api.get(`/api/departments/${id}.json`),
  },
  safe_work_authorizations: {
    index: params => api.get(`/api/safe_work_authorizations.json?${serializeParams(params)}`),
    show: id => api.get(`/api/safe_work_authorizations/${id}.json`),
  },
  permits: {
    index: params => api.get(`/api/permits_fdnies.json?${serializeParams(params)}`),
    show: id => api.get(`/api/permits_fdnies/${id}.json`),
  },
  constructionProjects: {
    index: params => api.get(`/api/construction_projects.json?${serializeParams(params)}`),
    show: id => api.get(`/api/construction_projects/${id}.json`),
  },
  biologicalSafeties: {
    index: params => api.get(`/api/biological_safeties.json?${serializeParams(params)}`),
    show: id => api.get(`/api/biological_safeties/${id}.json`),
  },
  equipmentTypes: {
    index: params => api.get(`/api/equipment_types.json?${serializeParams(params)}`),
    show: id => api.get(`/api/equipment_types/${id}.json`),
  },
  wcmcEquipments: {
    index: params => api.get(`/api/equipment.json?${serializeParams(params)}`),
    show: id => api.get(`/api/equipment/${id}.json`),
  },
  pfEs: {
    index: params => api.get(`/api/pf_es.json?${serializeParams(params)}`),
    show: id => api.get(`/api/pf_es/${id}.json`),
  },
  incidents: {
    index: params => api.get(`/api/incidents.json?${serializeParams(params)}`),
    show: id => api.get(`/api/incidents/${id}.json`),
  },
  accidents: {
    index: params => api.get(`/api/accidents.json?${serializeParams(params)}`),
    show: id => api.get(`/api/accidents/${id}.json`),
  },
  rad_inventory_items: {
    index: params => api.get(`/api/rad_inventory_items.json?${serializeParams(params)}`),
    show: id => api.get(`/api/rad_inventory_items/${id}.json`),
  },
  rad_licenses: {
    index: params => api.get(`/api/rad_licenses.json?${serializeParams(params)}`),
    show: id => api.get(`/api/rad_licenses/${id}.json`),
  },
  rad_purchases: {
    index: params => api.get(`/api/rad_purchases.json?${serializeParams(params)}`),
    show: id => api.get(`/api/rad_purchases/${id}.json`),
  },
  rad_permits: {
    index: params => api.get(`/api/rad_permits.json?${serializeParams(params)}`),
    show: id => api.get(`/api/rad_permits/${id}.json`),
  },
  labels: {
    index: params => api.get(`/api/labels.json?${serializeParams(params)}`),
    show: id => api.get(`/api/labels/${id}.json`),
  },
  internal_inspection_type: {
    index: params =>
      api.get(
        `/api/inspection_types.json?inspecting_party=ehs&active=true&${serializeParams(params)}`,
      ),
    show: id => api.get(`/api/inspection_types/${id}.json`),
  },
  external_inspection_type: {
    index: params =>
      api.get(`/api/inspection_types.json?inspecting_party=external&${serializeParams(params)}`),
    show: id => api.get(`/api/inspection_types/${id}.json`),
  },
  violation_type: {
    index: params => api.get(`/api/violation_types.json?${serializeParams(params)}`),
    show: id => api.get(`/api/violation_types/${id}.json`),
  },
  inspection: {
    internal: {
      delete: id => api.delete(`salute_portal/api/inspections/ehs_assessments/${id}`),
      create: params => api.post(`salute_portal/api/inspections/ehs_assessments?`, params),
      createAndFinalize: params =>
        api.post(`salute_portal/api/inspections/ehs_assessments/new/create_and_finalize`, params),
      show: id => api.get(`salute_portal/api/inspections/ehs_assessments/${id}.json`),
      update: (id, params) =>
        api.patch(`salute_portal/api/inspections/ehs_assessments/${id}.json`, params),
      reopen: (id, params) =>
        api.patch(`salute_portal/api/inspections/ehs_assessments/${id}/reopen.json`, params),
      finalize: (id, params) =>
        api.patch(
          `salute_portal/api/inspections/ehs_assessments/${id}/update_and_finalize.json`,
          params,
        ),
      new: params => api.get(`salute_portal/api/inspections/ehs_assessments/new.json`, params),
    },
    external: {
      delete: id => api.delete(`salute_portal/api/inspections/external_inspections/${id}`),
      create: params => api.post(`salute_portal/api/inspections/external_inspections`, params),
      createAndFinalize: params =>
        api.post(
          `salute_portal/api/inspections/external_inspections/new/create_and_finalize`,
          params,
        ),
      show: id => api.get(`salute_portal/api/inspections/external_inspections/${id}.json`),
      update: (id, params) =>
        api.patch(`salute_portal/api/inspections/external_inspections/${id}.json`, params),
      reopen: (id, params) =>
        api.patch(`salute_portal/api/inspections/external_inspections/${id}/reopen.json`, params),
      finalize: (id, params) =>
        api.patch(
          `salute_portal/api/inspections/external_inspections/${id}/update_and_finalize.json`,
          params,
        ),
      new: params => api.get(`salute_portal/api/inspections/external_inspections/new.json`, params),
    },
    inspectionChecklistQuestion: {
      saveCommentAndFile: (inspectionID, questionID, item) => {
        return api.patch(`/salute_portal/api/inspection_checklist_questions/${questionID}`, {
          inspection_id: inspectionID,
          inspection_checklist_question: item,
        });
      },
      index: params => api.get(`salute_portal/api/inspection_checklist_questions`, params),
    },
  },
  findings: {
    index: params => api.get(`salute_portal/api/findings?${serializeParams(params)}`),
    create: params => api.post(`salute_portal/api/findings`, params),
    createFinalized: params => api.post(`salute_portal/api/findings/new/create_finalized`, params),
    update: (id, params) => api.patch(`salute_portal/api/findings/${id}`, params),
    delete: id => api.delete(`salute_portal/api/findings/${id}`),
    updateAndFinalize: (id, params) =>
      api.patch(`salute_portal/api/findings/${id}/update_and_finalize`, params),
    updateReopen: (id, params) =>
      api.patch(`salute_portal/api/findings/${id}/update_reopen`, params),
  },
  violations: {
    create: params => api.post('salute_portal/api/violations', params),
    createAndFinalize: params =>
      api.post('salute_portal/api/violations/create_and_finalize', params),
    update: (id, params) => api.patch(`salute_portal/api/violations/${id}`, params),
    updateAndFinalize: (id, params) =>
      api.patch(`salute_portal/api/violations/${id}/update_and_finalize`, params),
    updateAndReopen: (id, params) =>
      api.patch(`salute_portal/api/violations/${id}/update_and_reopen`, params),
    index: params => api.get('salute_portal/api/violations', params),
    delete: id => api.delete(`salute_portal/api/violations/${id}`),
  },
  inspectionFindingDefinition: {
    index: params => api.get(`/api/inspection_finding_definitions.json?${serializeParams(params)}`),
    show: id => api.get(`/api/inspection_finding_definitions/${id}.json`),
  },
  inspectionFinding: {
    comments: {
      index: ({ id, ...params }) =>
        api.get(`/inspection_findings/${id}/comments?${serializeParams(params)}`),
      post: ({ id, ...params }) =>
        api.post(`/inspection_findings/${id}/comments?${serializeParams(params)}`),
    },
  },
  inspectionChecklistQuestion: {
    showByInspectionType: (inspectionTypeId, assessmentQueueId) => {
      let baseUrl = `/inspections/inspection_checklist_questions/show_by_inspection_type/${inspectionTypeId}`;
      if (assessmentQueueId) {
        baseUrl += `?assessment_queue_id=${assessmentQueueId}`;
      }
      return api.get(baseUrl);
    },
    showByInspection: inspectionId => {
      return api.get(
        `/inspections/inspection_checklist_questions/show_by_inspection/${inspectionId}`,
      );
    },
  },
  wasteManagement: {
    wasteGenerators: {
      index: params => api.get(`/api/waste_generators?${serializeParams(params)}`),
      show: id => api.get(`/api/waste_generators/${id}`),
    },
    wasteCafs: {
      index: params => api.get(`/api/waste_cafs?${serializeParams(params)}`),
      show: id => api.get(`/api/waste_cafs/${id}`),
    },
    wasteChemicalNames: {
      index: params => api.get(`/api/waste_chemical_names?${serializeParams(params)}`),
    },
    wasteTsdfs: {
      index: params => api.get(`/api/waste_tsdfs?${serializeParams(params)}`),
    },
    wasteManifests: {
      create: formValues =>
        api.post('/waste_management/waste_manifests', { waste_manifest: formValues }),
      update: ({ id, ...formValues }) =>
        api.put(`/waste_management/waste_manifests/${id}`, { waste_manifest: formValues }),
      destroy: id => api.delete(`/waste_management/waste_manifests/${id}`),
    },
    wasteManifestItems: {
      update: ({ id, wasteManifestId, ...formValues }) => {
        const params = { waste_manifest_line_item: formValues };

        return api.put(
          `/waste_management/waste_manifests/${wasteManifestId}/waste_manifest_line_items/${id}`,
          params,
        );
      },
      create: ({ wasteManifestId, ...formValues }) => {
        const params = { waste_manifest_line_item: formValues };

        return api.post(
          `/waste_management/waste_manifests/${wasteManifestId}/waste_manifest_line_items`,
          params,
        );
      },

      index: params =>
        api.get(
          `/waste_management/waste_manifests/${params.waste_manifest_id}/waste_manifest_line_items`,
        ),
      destroy: params =>
        api.delete(
          `/waste_management/waste_manifests/${params.wasteManifestId}/waste_manifest_line_items/${params.id}`,
        ),
    },
    wasteCollection: {
      index: params =>
        api.get(`/waste_management/waste_collections.json?${serializeParams(params)}`),
      update: (id, record) => {
        const params = { waste_chemical_item: record };

        return api.put(`/waste_management/waste_collections/${id}.json`, params);
      },
    },
    wasteTypes: {
      index: params => api.get(`/api/waste_types?${serializeParams(params)}`),
    },
    ums: {
      index: params => api.get(`/api/ums?${serializeParams(params)}`),
    },
    wasteSystemCodes: {
      index: params => api.get(`/api/waste_system_codes?${serializeParams(params)}`),
    },
    wasteNysTaxCodes: {
      index: params => api.get(`/api/waste_nys_tax_codes?${serializeParams(params)}`),
    },
  },
  uploadFile: {
    post: (url, params) => api.post(`${url}?${serializeParams(params)}`),
  },
  fireDrillLocation: {
    show: (fireDrillID, fireDrillLocationID) =>
      api.get(`/fire_drills/${fireDrillID}/fire_drill_locations/${fireDrillLocationID}`),
  },
  fireDrillLocationMonitor: {
    destroy: (fireDrillID, fireDrillLocationID, monitorID) =>
      api.delete(
        `/fire_drills/${fireDrillID}/fire_drill_locations/${fireDrillLocationID}/fire_drill_location_monitors/${monitorID}`,
      ),
  },
  monitorChecklistQuestion: {
    questionAnswers: (fireDrillID, fireDrillLocationID, monitorID) => {
      return api.get(
        `/fire_drills/${fireDrillID}/fire_drill_locations/${fireDrillLocationID}/fire_drill_location_monitors/${monitorID}/monitor_question_answers`,
      );
    },
    setAnswers: (fireDrillID, fireDrillLocationID, monitorID, answers) => {
      return api.post(
        `/fire_drills/${fireDrillID}/fire_drill_locations/${fireDrillLocationID}/fire_drill_location_monitors/${monitorID}/monitor_question_answers/set_answers`,
        {
          answers: {
            items: answers,
          },
        },
      );
    },
    saveCommentAndFile: (fireDrillID, fireDrillLocationID, monitorID, questionID, item) => {
      return api.patch(
        `/fire_drills/${fireDrillID}/fire_drill_locations/${fireDrillLocationID}/fire_drill_location_monitors/${monitorID}/monitor_question_answers/${questionID}`,
        {
          fire_drill_question_answers: item,
        },
      );
    },
  },
  conductorChecklistQuestion: {
    questionAnswers: fireDrillID => {
      return api.get(`/fire_drills/${fireDrillID}/conductor_question_answers`);
    },
    setAnswers: (fireDrillID, answers) => {
      return api.post(`/fire_drills/${fireDrillID}/conductor_question_answers/set_answers`, {
        answers: {
          items: answers,
        },
      });
    },
    saveCommentAndFile: (fireDrillID, questionID, item) => {
      return api.patch(`/fire_drills/${fireDrillID}/conductor_question_answers/${questionID}`, {
        fire_drill_question_answers: item,
      });
    },
  },
};
